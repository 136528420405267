'use client';
import React, { useEffect, useState } from 'react';
import styles from './VerticalTextCarousel.module.scss';

const VerticalTextCarousel = ({ items, interval = 1250 }: any) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setActiveIndex(prevActiveIndex => (prevActiveIndex + 1) % items.length);
    }, interval);

    return () => {
      clearTimeout(timer);
    };
  }, [activeIndex, items.length, interval]);

  const renderItem = (item: any, index: number) => {
    let className = styles.carouselItem;
    if (index === activeIndex) {
      className += ` ${styles.active}`;
    } else if (
      index === activeIndex - 1 ||
      (activeIndex === 0 && index === items.length - 1)
    ) {
      className += ` ${styles.previous}`;
    } else {
      className += ` ${styles.disabled}`;
    }

    return (
      <span key={index} className={className}>
        {` ${item}`}
      </span>
    );
  };

  return (
    <div className={styles.carouselContainer}>
      {items.map((item: any, index: number) => renderItem(item, index))}
    </div>
  );
};

export default VerticalTextCarousel;
