import(/* webpackMode: "eager" */ "/vercel/path0/components/LandingPage/DynamicReferralCheck.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/components/LandingPage/NavBar.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/LandingPage/LogoWall.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/LandingPage/LandingPage.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/VerticalTextCarousel/VerticalTextCarousel.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/images/vocalini_logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/logos/Amazon_Logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/logos/Canva_Logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/logos/LAUSD_Logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/logos/Newlight_Logo.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/logos/Nobel_Logo.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/logos/Snapchat_Logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/logos/UCBerkeley_Logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/logos/UCLA_Logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/logos/UCLAUNCH_Logo.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/logos/USC_Logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/Services/clientFetch.ts")