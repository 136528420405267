'use client';

import { useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';

export default function DynamicReferralCheck() {
  // const router = useRouter();
  const [isClient, setClient] = useState(false);
  const params = useSearchParams();
  const ref = params?.get('ref') || '';
  // to check whether window object is available
  useEffect(() => {
    setClient(typeof window !== 'undefined');
  }, []);

  useEffect(() => {
    if (!(ref == '')) {
      // if 'ref' exists, save it to localStorage
      localStorage.setItem('ref', ref);
    }
  }, [ref]);

  return null;
}
